import {
  BellSlashIcon,
  BellSnoozeIcon,
  ClockIcon,
  MapPinIcon,
  MegaphoneIcon,
  ReceiptRefundIcon,
  RocketLaunchIcon,
  TrashIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import CheckIcon from "@rsuite/icons/legacy/Check";
import moment from "moment";
import "moment/locale/id";
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  type FC,
} from "react";
import { LiaTasksSolid } from "react-icons/lia";
import { TfiLocationPin } from "react-icons/tfi";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Modal,
  Notification,
  SelectPicker,
  Timeline,
  Uploader,
} from "rsuite";
import Avatar from "rsuite/esm/Avatar";
import MainLayout from "../components/main_layout";
import MapComponent from "../components/map";
import ModalTask from "../components/modal_task";
import ModalVisit from "../components/modal_visit";
import WebcamPage from "../components/web_cam";
import { Activity } from "../model/activity";
import { Attendance, AttendanceWithSite } from "../model/attendance";
import { Profile } from "../model/auth";
import { Schedule } from "../model/schedule";
import { LoadingContext } from "../objects/loading_context";
import { createActivity, getEmployees } from "../repositories/activity";

import { BarcodeScanner } from "react-barcode-scanner";
import "react-barcode-scanner/polyfill";
import CurrencyInput from "react-currency-input-field";
import {
  BsActivity,
  BsBank,
  BsCalendar2Check,
  BsCash,
  BsQrCodeScan,
} from "react-icons/bs";
import { RiCustomerService2Line } from "react-icons/ri";
import { SlBriefcase } from "react-icons/sl";
import { TbClockPlus } from "react-icons/tb";
import Webcam from "react-webcam";
import { Announcement } from "../model/annoucement";
import { Company } from "../model/company";
import { Employee } from "../model/employee";
import { Overtime } from "../model/overtime";
import { WorkReport, WorkReportReq } from "../model/work_report";
import { WorkType } from "../model/work_type";
import { getAnnouncements } from "../repositories/announcement";
import {
  attendanceSite,
  breakAttendance,
  todayAttendance,
} from "../repositories/attendance";
import {
  getNotificationCount,
  getProfile,
  todayOvertime,
  todaySchedules,
} from "../repositories/auth";
import {
  checkWorkReport,
  createWorkReport,
  getWorkReports,
} from "../repositories/work_report";
import { getWorkTypes } from "../repositories/work_type";
import { APPROVER_ID, ATTENDANCE_PROBLEM, TOKEN } from "../utils/constant";
import {
  asyncLocalStorage,
  b64toBlob,
  confirmDelete,
  customFetch,
  getStorageCompanyID,
  initials,
  money,
  nl2br,
  numberToDuration,
  randomStr,
} from "../utils/helper";
import { errorToast, successToast } from "../utils/helper_ui";
import { MdOutlineModeOfTravel } from "react-icons/md";

interface HomeProps {}

const Home: FC<HomeProps> = ({}) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [attendance, setAttendance] = useState<Attendance | null>(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [showModal, setShowModal] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date()); // State untuk menyimpan waktu saat ini
  const [modalTaskOpen, setModalTaskOpen] = useState(false);
  const [modalVisitOpen, setModalVisitOpen] = useState(false);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [isBreakIn, setIsBreakIn] = useState(false);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [countNofif, setCountNofif] = useState(0);
  const [overtime, setOvertime] = useState<Overtime | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [mounted, setMounted] = useState(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [visitLat, setVisitLat] = useState<number | null>(null);
  const [visitLng, setVisitLng] = useState<number | null>(null);
  const [visitPicture, setVisitPicture] = useState("");
  const [visitMode, setVisitMode] = useState("VISIT_IN");
  const [pendingVisitActivity, setPendingVisitActivity] =
    useState<Activity | null>(null);
  const [isPendingVisit, setIsPendingVisit] = useState(false);
  const nav = useNavigate();
  const [modalDailyWork, setModalDailyWork] = useState(false);
  const [workTypes, setWorkTypes] = useState<WorkType[]>([]);
  const [paths, setPaths] = useState<string[]>([]);
  const [urls, setUrls] = useState<string[]>([]);
  const [token, setToken] = useState("");
  const [selectedWorkTypeID, setSelectedWorkTypeID] = useState("");
  const [selectedWorkType, setSelectedWorkType] = useState<WorkType | null>(
    null
  );
  const [workQty, setWorkQty] = useState(0);
  const [mountValue, setMountValue] = useState(true);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeeId, setEmployeeId] = useState<string | null>(null);
  const [workReports, setWorkReports] = useState<WorkReport[]>([]);
  const [modalQR, setModalQR] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const [modalWorkReport, setModalWorkReport] = useState(false);
  const [workreportData, setworkreportData] = useState<string[]>([]);
  const [attendanceSiteData, setAttendanceSiteData] = useState<string[]>([]);
  const [notes, setNotes] = useState("");
  const [modalCamera, setModalCamera] = useState(false);
  const webcamRef = useRef<Webcam>(null);
  const videoConstraints = {
    facingMode: "user",
  };

  const getAllAnnouncements = async () => {
    try {
      setIsLoading(true);
      let resp = await getAnnouncements({ page: 1, limit: 5 });
      const respJson = await resp.json();
      setAnnouncements(respJson.data);
      // setPagination(respJson.pagination);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (qrValue) {
      let qrData = qrValue.split("|");
      if (qrData[0] == "ATTENDANCE_SITE") {
        qrData.shift();
        console.log(qrData);
        setAttendanceSiteData(qrData);
        setModalCamera(true);
      }
      if (qrData[0] == "WORK_REPORT") {
        qrData.shift();
        setworkreportData(qrData);
        // console.log(qrData);
        setModalWorkReport(true);
      }
      setQrValue("");
    }
  }, [qrValue]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      // console.log("attendanceSiteData",attendanceSiteData)
      setIsLoading(true);
      const blob = b64toBlob(
        imageSrc.split("data:image/jpeg;base64,")[1],
        "image/jpg"
      );
      // const blobUrl = URL.createObjectURL(blob);
      const formData = new FormData();

      formData.append("file", blob);
      formData.append("flipped", "1");
      // window.open(blobUrl)
      customFetch(
        `user/upload`,
        {
          method: "POST",
          body: formData,
        },
        true
      )
        .then((v) => v.json())
        .then((v) => {
          let req: AttendanceWithSite = {
            id: attendanceSiteData[0],
            unique_code: attendanceSiteData[1],
            is_face_detected: true,
            picture: v.data.path,
            mode: "CLOCK_IN",
          };
          if (attendance) {
            req.attendance_id = attendance.id;
            req.mode = "CLOCK_OUT";
          }
          // console.log(attendanceSiteData)
          // console.log(req)
          setIsLoading(true);
          attendanceSite(req)
            .then(() => {
              window.location.reload();
            })
            .catch((e) => {
              errorToast(e);
            })

            .finally(() => {
              setModalCamera(false);
              setAttendanceSiteData([]);
              setIsLoading(false);
            });
        })
        .catch((e) => {
          errorToast(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [webcamRef, attendanceSiteData]);

  useEffect(() => {
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
    asyncLocalStorage.getItem(APPROVER_ID).then((approverId) => {
      setEmployeeId(approverId);
      getEmployees(
        { page: 1, limit: 5, search: "" },
        { employee_id: approverId }
      )
        .then((v) => v.json())
        .then((v) => {
          setEmployees(v.data);
        });
    });
    getProfile()
      .then((v) => v.json())
      .then((v) => setProfile(v.data))
      .catch((err) => {
        // setError(err);
      });
    getStorageCompanyID().then((v) => {
      if (v) {
        getNotificationCount()
          .then((v) => v.json())
          .then((v) => setCountNofif(v.data.count))
          .catch((err) => {});
        // getAllActivities()
        todayAttendance()
          .then((v) => v.json())
          .then((v) => {
            if (v.data.id) {
              setAttendance(v.data);
            }
          })
          .catch((err) => {
            // setError(err);
          });
        const interval = setInterval(() => {
          setCurrentTime(new Date());
        }, 1000);
        getAllAnnouncements();
      }
    });
  }, []);

  useEffect(() => {
    if (profile?.employee?.salary_type == "DAILY_PIECE_WORK") {
      getWorkTypes()
        .then((v) => v.json())
        .then((v) => setWorkTypes(v.data))
        .catch((err) => {});
    }
    if (attendance) {
      getWorkReports({ page: 1, limit: 0 }, attendance?.id)
        .then((v) => v.json())
        .then((v) => {
          setWorkReports(v.data);
        })
        .catch((err) => {});
    }
  }, [profile, attendance]);

  useEffect(() => {
    for (const act of activities) {
      if (act.activity_type == "VISIT" && act.status == "IN_PROGRESS") {
        setIsPendingVisit(true);
        setPendingVisitActivity(act);
        break;
      }
    }
  }, [activities]);

  useEffect(() => {
    if (attendance) {
      setActivities(attendance.activities);
    }
    getStorageCompanyID().then((v) => {
      if (v) {
        todaySchedules({ page: 1, limit: 10 })
          .then((v) => v.json())
          .then((v) => setSchedules(v.data))
          .catch((err) => {});
        todayOvertime()
          .then((v) => v.json())
          .then((v) => setOvertime(v.data))
          .catch((err) => {});
      }
    });
  }, [attendance]);

  // const getAllActivities = () => {
  //   getMyActivities(
  //     { page: 1, limit: 100 },
  //     {
  //       order: "start_date asc, start_time asc",
  //       dateRange: [
  //         moment().local().startOf("day").toDate(),
  //         moment().local().endOf("day").toDate(),
  //       ],
  //       filter: "assigned",
  //     }
  //   )
  //     .then((v) => v.json())
  //     .then((v) => setActivities(v.data));
  // };
  const renderOvertime = () => {
    if (!overtime) return null;
    return (
      <div className="flex-1 flex flex-col mt-4  bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0 ">
        <p className="font-bold flex mb-2"> Lembur Hari ini</p>
        <div
          className="flex flex-row  gap-4 cursor-pointer"
          onClick={() => nav(`/overtime/${overtime?.id}`)}
        >
          <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
            <Moment format="DD">{overtime?.date_requested}</Moment>
            <Moment
              format="MMM YYYY"
              className="text-[8pt] text-center text-red-400"
            >
              {overtime?.date_requested}
            </Moment>
          </div>
          <div className="flex flex-col flex-1">
            <div className="font-bold  ">{overtime?.reason}</div>
            <div>
              {overtime?.status == "PENDING" && (
                <Badge
                  color="cyan"
                  className="text-xs"
                  content={overtime?.status}
                />
              )}
              {overtime?.status == "REVIEWED" && (
                <Badge
                  color="green"
                  className="text-xs"
                  content={overtime?.status}
                />
              )}
              {overtime?.status == "APPROVED" && (
                <Badge
                  color="blue"
                  className="text-xs"
                  content={overtime?.status}
                />
              )}
              {overtime?.status == "REJECTED" && (
                <Badge
                  color="red"
                  className="text-xs"
                  content={overtime?.status}
                />
              )}
            </div>
            <div className="flex  flex-1 flex-row justify-between">
              <div className="py-2">
                <p className="text-sm font-[700]">Mulai</p>
                <Moment format="HH:mm">{overtime?.start_time_request}</Moment>
              </div>
              <div className="py-2">
                <p className="text-sm font-[700]">Selesai</p>
                <Moment format="HH:mm">{overtime?.end_time_request}</Moment>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderAnnouncements = () => {
    if (announcements.length == 0) return null;
    return (
      <div className="p-4 bg-gradient-to-br from-rose-500 to-rose-700  text-white rounded-xl shadow-lg shadow-gray-200 flex justify-between items-center mt-4">
        <div className="flex-1 flex flex-col">
          <p className="font-bold flex mb-2"> Pengumuman</p>
          {announcements.map((e) => (
            <div
              key={e.id}
              className="flex flex-row  gap-4  rounded-lg   mb-4 last:mb-0 cursor-pointer "
              onClick={() => nav(`/announcement/${e.id}`)}
            >
              <div className="w-16 h-16 bg-white shadow-lg shadow-white-100 rounded-lg flex-col flex justify-center items-center p-2">
                <Moment format="DD" className="text-gray-900 text-lg font-bold">
                  {e.start_date}
                </Moment>
                <Moment
                  format="MMM YYYY"
                  className="text-[8pt] text-center text-red-400"
                >
                  {e.start_date}
                </Moment>
              </div>

              <div className="flex flex-col ">
                <div className=" font-bold">{e.name}</div>
                <div className="text-white-500 text-sm">
                  <Moment format="DD MMM YYYY">{e?.start_date}</Moment> ~
                  <Moment format="DD MMM YYYY">{e?.end_date}</Moment>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const renderSchedules = () => {
    if (schedules.length == 0) return null;
    return (
      <div className="p-4 bg-gradient-to-br from-teal-500 to-teal-700  text-white rounded-xl shadow-lg shadow-gray-200 flex justify-between items-center mt-4 ">
        <div className="flex-1 flex flex-col">
          <p className="font-bold flex mb-2"> Jadwal Hari ini</p>
          {schedules.map((e) => (
            <div className="mb-2 last:mb-0" key={e.id}>
              <p className="font-[600]">{e.name}</p>
              {e.schedule_type == "WEEKLY" && (
                <p className="text-sm">
                  {e.start_time} - {e.end_time}
                </p>
              )}
              {e.schedule_type == "SINGLE_DATE" && (
                <div>
                  <p className="text-sm">
                    {moment(e.start_date).format("DD MMM YYYY")}
                  </p>
                  <small>
                    {e.start_time} ~ {e.end_time}
                  </small>
                </div>
              )}
              {e.schedule_type == "DATERANGE" && (
                <div>
                  <p className="text-sm">
                    {moment(e.start_date).format("DD MMM YYYY")} ~{" "}
                    {moment(e.end_date).format("DD MMM YYYY")}
                  </p>
                  <small>
                    {e.start_time} ~ {e.end_time}
                  </small>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const clearForm = () => {
    setNotes("");
    setSelectedWorkTypeID("");
    setSelectedWorkType(null);
    setWorkQty(0);
    setMountValue(false);
    setTimeout(() => {
      setMountValue(true);
    }, 50);
    setPaths([]);
    setUrls([]);
  };
  const renderMenuIcon = () => (
    <div className="p-4 bg-white rounded-xl shadow-lg shadow-violet-600 flex flex-wrap mt-4 gap-2 items-center justify-center gap-y-4 mb-4">
      <div
        className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
        onClick={() => {
          nav("/leave");
        }}
      >
        <div className=" aspect-square rounded-full flex justify-center items-center w-8 h-8 bg-red-500">
          <ReceiptRefundIcon className="w-4 text-white" />
        </div>
        <p className="text-[7pt] text-center text-gray-600">Cuti/Izin</p>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
        onClick={() => {
          nav("/activity");
        }}
      >
        <div className=" aspect-square rounded-full flex justify-center items-center w-8 h-8 bg-green-600">
          <BsActivity size={24} className="w-4 text-white" />
        </div>
        <p className="text-[7pt] text-center text-gray-600">Aktifitas</p>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
        onClick={() => {
          nav("/reimbursement");
        }}
      >
        <div className=" aspect-square rounded-full flex justify-center items-center w-8 h-8 bg-blue-500">
          <WalletIcon className="w-4 text-white" />
        </div>
        <p className="text-[7pt] text-center text-gray-600">Reimburse</p>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
        onClick={() => {
          setModalQR(true);
        }}
      >
        <div className=" aspect-square rounded-full flex justify-center items-center w-8 h-8 bg-orange-500">
          <BsQrCodeScan className="w-4 text-white" />
        </div>
        <p className="text-[7pt] text-center text-gray-600">QR Scan</p>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
        onClick={() => {
          nav("/announcement");
        }}
      >
        <div className=" aspect-square rounded-full flex justify-center items-center w-8 h-8 bg-sky-500">
          <MegaphoneIcon className="w-4 text-white" />
        </div>
        <p className="text-[7pt] text-center text-gray-600">Pengumuman</p>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
        onClick={() => {
          nav("/cash_advance");
        }}
      >
        <div className=" aspect-square rounded-full flex justify-center items-center w-8 h-8 bg-emerald-500">
          <BsCash className="w-4 text-white" />
        </div>
        <p className="text-[7pt] text-center text-gray-600">Cash Advance</p>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
        onClick={() => {
          nav("/employee_loan");
        }}
      >
        <div className=" aspect-square rounded-full flex justify-center items-center w-8 h-8 bg-fuchsia-700">
          <BsBank className="w-4 text-white" />
        </div>
        <p className="text-[7pt] text-center text-gray-600">Pinjaman</p>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
        onClick={() => {
          nav("/overtime");
        }}
      >
        <div className=" aspect-square rounded-full flex justify-center items-center w-8 h-8 bg-amber-700">
          <TbClockPlus className="w-4 text-white" />
        </div>
        <p className="text-[7pt] text-center text-gray-600">Lembur</p>
      </div>
      {profile?.employee?.salary_type == "DAILY_PIECE_WORK" && (
        <div
          className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
          onClick={() => {
            nav("/work_report");
          }}
        >
          <div className=" aspect-square rounded-full flex justify-center items-center w-8 h-8 bg-indigo-600">
            <BsCalendar2Check className="w-4 text-white" />
          </div>
          <p className="text-[7pt] text-center whitespace-nowrap text-gray-600">
            Pekerjaan Harian
          </p>
        </div>
      )}
      {profile?.employee?.salary_type == "MONTHLY" && (
        <div
          className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
          onClick={() => {
            setModalTaskOpen(true);
          }}
        >
          <div className=" aspect-square rounded-full flex justify-center items-center w-8 h-8 bg-lime-600">
            <LiaTasksSolid className="w-4 text-white" />
          </div>
          <p className="text-[7pt] text-center whitespace-nowrap  text-gray-600">
            Task
          </p>
        </div>
      )}
      <div
        className="flex flex-col justify-center items-center gap-1 w-[60px] cursor-pointer"
        onClick={() => {
          if (!attendance) {
            errorToast("Silahkan Clock IN terlebih dahulu");
            return;
          }
          if (attendance?.clock_out) {
            errorToast("Anda sudah clock out, tidak bisa melakukan kunjungan");
            return;
          }
          if (!isPendingVisit) {
            setVisitMode("VISIT_IN");
          } else {
            setVisitMode("VISIT_OUT");
          }
          setShowModal(true);
        }}
      >
        <div
          className={` aspect-square rounded-full flex justify-center items-center w-8 h-8 ${
            isPendingVisit ? "bg-red-600" : "bg-violet-600"
          }`}
        >
          <MdOutlineModeOfTravel className="w-4 text-white" />
        </div>
        <p className="text-[7pt] text-center whitespace-nowrap  text-gray-600">
          Kunjungan
        </p>
      </div>
    </div>
  );
  const renderHeader = () => (
    <div className="bg-gradient-to-br from-sky-500 to-violet-600 text-white  w-full  px-6 pb-3 pt-6 shadow-xl shadow-gray-100 rounded-b-xl flex-col mb-4">
      <div className=" flex justify-between   gap-2">
        <div className="flex flex-row gap-4 items-center mb-2">
          <Avatar
            size="sm"
            bordered
            circle
            src={profile?.picture}
            alt={initials(profile?.full_name)}
          />
          <div>
            <p className="text-xs">{company?.name}</p>
            <div className="text-xl">
              {profile?.employee
                ? profile?.employee?.full_name
                : profile?.full_name}
            </div>
            <p className="text-xs">{profile?.role_name}</p>
          </div>
        </div>
      </div>

      {renderClock()}
      {renderMenuIcon()}
    </div>
  );
  const renderClock = () => (
    <div className="flex justify-center flex-col items-center mb-8 mt-4">
      <h3 className="text-4xl font-bold text-center">
        {moment(currentTime).format("HH:mm:ss").replaceAll("pukul", "")}
      </h3>
      <p className="text-gray-200">
        {moment(currentTime).locale("id").format("dddd, DD MMMM YYYY")}
      </p>
    </div>
  );
  const renderCSIcon = () => (
    <div
      className=" aspect-square rounded-full flex justify-center items-center w-10 h-10 bg-sky-600 fixed bottom-16 right-2"
      style={{ zIndex: 2 }}
      onClick={() => window.open("https://wa.me/628977726030")}
    >
      <RiCustomerService2Line className="text-white" />
    </div>
  );

  const renderAttendanceModule = () => {
    if (!attendance) return null;
    return (
      <div className="p-4 bg-white rounded-xl shadow-lg shadow-gray-200 flex justify-between items-center flex-col">
        <div className="max-h-[300px] overflow-y-auto">
          <Timeline className="custom-timeline">
            <Timeline.Item dot={<ClockIcon className="rs-icon" />}>
              <div className="text-lg font-bold justify-between flex items-center">
                <div className="flex flex-col">
                  <Badge content="Clock In" color="blue" />
                  {/* <Moment format="HH:mm">{attendance.clock_in}</Moment> */}
                  <p>
                    {moment(attendance.clock_in)
                      .format("HH:mm")
                      .replaceAll("pukul", "")}
                  </p>
                </div>
                <a
                  href={attendance.clock_in_picture}
                  target="_blank"
                  className="flex items-center justify-center"
                >
                  <Avatar size="sm" circle src={attendance.clock_in_picture} />
                </a>
              </div>

              <p
                className="text-sm mb-4 hover:font-bold"
                onClick={() => {
                  window.open(
                    `https://www.google.com/maps/place/${attendance.clock_in_lat},${attendance.clock_in_lng}`
                  );
                }}
              >
                {attendance.clock_in_notes}
              </p>
              <MapComponent
                latitude={attendance.clock_in_lat}
                longitude={attendance.clock_in_lng}
                locationName={attendance.clock_in_notes}
                styles={{ borderRadius: 10 }}
                height={120}
                width={null}
              />
              {attendance.status == "PENDING" && attendance.remarks && (
                <div className="text-sm mt-4 p-2 bg-yellow-300 rounded-lg">
                  {
                    ATTENDANCE_PROBLEM.find(
                      (e) => e.value == attendance.remarks
                    )?.label
                  }
                </div>
              )}
            </Timeline.Item>
            {activities?.map((e) => (
              <Timeline.Item
                key={e.id}
                dot={
                  e.activity_type == "TO_DO" ? (
                    <RocketLaunchIcon className="rs-icon" />
                  ) : e.activity_type == "BREAK_START" ? (
                    <BellSnoozeIcon className="rs-icon" />
                  ) : e.activity_type == "BREAK_END" ? (
                    <BellSlashIcon className="rs-icon" />
                  ) : (
                    <MapPinIcon className="rs-icon" />
                  )
                }
                className="cursor-pointer"
              >
                <div className="text-lg font-bold justify-between flex items-center ">
                  {/* <Moment format="HH:mm">{`${e.start_date} ${e.start_time}`}</Moment> */}
                  <p>
                    {moment(`${e.start_date} ${e.start_time}`)
                      .format("HH:mm")
                      .replaceAll("pukul", "")}
                  </p>
                </div>
                <p
                  className="text-sm  hover:font-bold"
                  onClick={() => {
                    if (e.activity_type == "TO_DO") {
                      nav(`/task/${e.id}`);
                    }
                  }}
                >
                  {e.name}
                </p>
                <p
                  className="text-xs mb-4"
                  dangerouslySetInnerHTML={{ __html: nl2br(e.description) }}
                ></p>

                <p
                  className="text-xs  hover:font-bold"
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps/place/${e.lat},${e.lng}`
                    );
                  }}
                >
                  {e.location}
                </p>

                {e.activity_type == "VISIT" && (
                  <MapComponent
                    latitude={e.lat}
                    longitude={e.lng}
                    locationName={e.name}
                    styles={{ borderRadius: 10, marginBotton: 20 }}
                    height={120}
                    width={null}
                  />
                )}
                {e.activity_type == "VISIT" &&
                  e.visit_out_lat &&
                  e.visit_out_lng &&
                  e.visit_out_location && (
                    <p
                      className="text-xs  hover:font-bold mt-4"
                      onClick={() => {
                        window.open(
                          `https://www.google.com/maps/place/${e.visit_out_lat},${e.visit_out_lng}`
                        );
                      }}
                    >
                      {e.visit_out_location}
                    </p>
                  )}
                {e.activity_type == "VISIT" &&
                  e.visit_out_lat &&
                  e.visit_out_lng && (
                    <MapComponent
                      latitude={e.visit_out_lat}
                      longitude={e.visit_out_lng}
                      locationName={e.visit_out_location}
                      styles={{ borderRadius: 10, marginBotton: 20 }}
                      height={120}
                      width={null}
                    />
                  )}
              </Timeline.Item>
            ))}
            {(workReports ?? []).length > 0 && (
              <Timeline.Item dot={<SlBriefcase className="rs-icon" />}>
                <ul className="py-2 list-disc">
                  {workReports?.map((e) => (
                    <li key={e.id} className="mb-2">
                      {/* <Moment format="HH:mm">{`${e.start_date} ${e.start_time}`}</Moment> */}
                      <p className="font-bold text-lg">{e.work_type?.name}</p>
                      <p className="text-sm">
                        {money(e.units_completed)} {e.work_type?.unit_name}
                      </p>
                    </li>
                  ))}
                </ul>
              </Timeline.Item>
            )}

            {/* <Timeline.Item>
  <p>March 1, 11:34</p>
  <p>The package really waits for the company to pick up the goods</p>
</Timeline.Item> */}
            {attendance && attendance.clock_out && (
              <Timeline.Item
                dot={
                  <CheckIcon style={{ background: "#15b215", color: "#fff" }} />
                }
              >
                <div className="text-lg font-bold justify-between flex items-center">
                  <div className="flex flex-col">
                    <Badge content="Clock Out" color="red" />
                    {/* <Moment format="HH:mm">{attendance.clock_out}</Moment> */}
                    <p>
                      {moment(`${attendance.clock_out}`)
                        .format("HH:mm")
                        .replaceAll("pukul", "")}
                    </p>
                  </div>

                  <a
                    href={attendance.clock_out_picture}
                    target="_blank"
                    className="flex items-center justify-center"
                  >
                    <Avatar
                      size="sm"
                      circle
                      src={attendance.clock_out_picture}
                    />
                  </a>
                </div>
                <p
                  className="text-sm mb-4 hover:font-bold"
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps/place/${attendance.clock_out_lat},${attendance.clock_out_lng}`
                    );
                  }}
                >
                  {attendance.clock_out_notes}
                </p>
                <MapComponent
                  latitude={attendance.clock_out_lat}
                  longitude={attendance.clock_out_lng}
                  locationName={attendance.clock_out_notes}
                  styles={{ borderRadius: 10 }}
                  height={120}
                  width={null}
                />
                {attendance.status == "PENDING" &&
                  attendance.clock_out_remarks && (
                    <div className="text-sm mt-4 p-2 bg-yellow-300 rounded-lg">
                      {
                        ATTENDANCE_PROBLEM.find(
                          (e) => e.value == attendance.clock_out_remarks
                        )?.label
                      }
                    </div>
                  )}
              </Timeline.Item>
            )}
          </Timeline>
        </div>

        {attendance && !attendance.clock_out && (
          <div className="grid grid-cols-2 gap-4 mt-4 w-full">
            {profile?.employee?.salary_type == "MONTHLY" ? (
              <Button
                appearance="primary"
                color="orange"
                onClick={() => setModalTaskOpen(true)}
              >
                <LiaTasksSolid /> <span>TASK</span>
              </Button>
            ) : (
              <Button
                appearance="primary"
                color="orange"
                onClick={() => setModalDailyWork(true)}
              >
                <LiaTasksSolid /> <span>Laporan Pekerjaan</span>
              </Button>
            )}

            {isPendingVisit ? (
              <Button
                appearance="primary"
                color="red"
                onClick={() => {
                  setShowModal(true);
                  setVisitMode("VISIT_OUT");
                }}
              >
                <span>KUNJUNGAN SELESAI</span>
              </Button>
            ) : (
              <Button
                appearance="primary"
                color="cyan"
                onClick={() => {
                  setShowModal(true);
                  setVisitMode("VISIT_IN");
                }}
              >
                <TfiLocationPin /> <span>KUNJUNGAN</span>
              </Button>
            )}
          </div>
        )}
        {/* {attendance.status == "PENDING" && (
        <div className="text-sm mt-4 p-2 bg-yellow-300 rounded-lg">
          Status Presensi anda sedang ditinjau dikarenakan masalah :{" "}
          <ul className=" list-disc pl-8">
            {attendance.remarks && (
              <li className="font-bold">
                Clock In :{" "}
                {attendance.remarks &&
                  ATTENDANCE_PROBLEM.find(
                    (e) => e.value == attendance.remarks
                  )?.label}
              </li>
            )}
            {attendance.clock_out_remarks && (
              <li className="font-bold">
                Clock Out :{" "}
                {attendance.clock_out_remarks &&
                  ATTENDANCE_PROBLEM.find(
                    (e) => e.value == attendance.clock_out_remarks
                  )?.label}
              </li>
            )}
          </ul>
        </div>
      )} */}
      </div>
    );
  };
  return (
    <MainLayout onLoadCompany={(val) => setCompany(val)}>
      {renderCSIcon()}
      {renderHeader()}
      {!profile?.employee && (
        <Notification closable type="warning" header="Perhatian">
          User anda belum memiliki data pegawai, silakan hubungi Admin
        </Notification>
      )}
      {profile?.employee && (
        <div className="p-4  w-full  flex-1">
          <div className="flex flex-row justify-between items-center"></div>

          {attendance == null && (
            <div className="p-4 bg-white rounded-xl shadow-lg shadow-gray-200 flex justify-between items-center">
              <div className="flex justify-between w-full items-center">
                <p className="text-sm mr-2">Anda belum clock in hari ini</p>
                <Button
                  appearance="primary"
                  color="green"
                  onClick={() => nav(`/clockIn`)}
                >
                  Clock in
                </Button>
              </div>
            </div>
          )}

          {renderAttendanceModule()}
          {attendance && (
            <div className="p-4 bg-white rounded-xl shadow-lg shadow-gray-200 flex justify-between items-center mt-4">
              <div className="flex-1 flex flex-col">
                <p className="font-bold flex "> Durasi Kerja:</p>
                <small>
                  {numberToDuration(
                    moment(attendance.clock_out ?? currentTime).diff(
                      moment(attendance.clock_in),
                      "minutes"
                    )
                  )}
                </small>
              </div>
              {!attendance.clock_out && (
                <div className="flex justify-between flex-1 gap-4">
                  <Button
                    className="w-full"
                    appearance="primary"
                    color="red"
                    onClick={() => {
                      if (attendance) {
                        nav("/clockOut/" + attendance.id);
                      }
                    }}
                  >
                    Clock Out
                  </Button>
                  {!attendance?.break_end && (
                    <Button
                      className="w-full"
                      appearance="ghost"
                      color="red"
                      onClick={() => {
                        let isBreakEnd = attendance?.break_start !== "";
                        confirmDelete(
                          () => {
                            setIsLoading(true);
                            breakAttendance(attendance.id!, {
                              clock_in: attendance.clock_in,
                              break_start: !isBreakEnd
                                ? moment().local().format("HH:mm:ss")
                                : null,
                              break_end: isBreakEnd
                                ? moment().local().format("HH:mm:ss")
                                : null,
                            })
                              .then((v) => {
                                //
                                createActivity({
                                  activity_type: isBreakEnd
                                    ? "BREAK_END"
                                    : "BREAK_START",
                                  start_date: moment().local().toISOString(),
                                  start_time: moment()
                                    .local()
                                    .format("HH:mm:ss"),
                                  description: isBreakEnd
                                    ? "Selesai Istirahat"
                                    : "Istirahat",
                                  files: [],
                                  attendance_id: attendance.id,
                                  status: "FINISHED",
                                  assigned_to_id: profile.employee_id,
                                }).then((v) => {
                                  window.location.reload();
                                });
                              })
                              .catch((err) => errorToast(err))
                              .finally(() => {
                                setIsLoading(false);
                              });
                          },
                          "Kirim Data Istirahat",
                          `Anda ${
                            isBreakEnd ? "selesai beristirahat" : "beristirahat"
                          } pada ${moment().local().format("HH:mm")}`,
                          "Kirim"
                        );
                      }}
                    >
                      {attendance?.break_start ? "Selesai" : "Istirahat"}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}

          {renderOvertime()}
          {renderAnnouncements()}
          {renderSchedules()}
          <div className="h-16"></div>
        </div>
      )}
      <Modal
        classPrefix="cam"
        size={"full"}
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        style={{ zIndex: 1000 }}
      >
        <Modal.Body className="">
          {showModal && (
            <WebcamPage
              onCapture={async (val) => {
                if (!val.latitude || !val.longitude) {
                  errorToast("WARNING, Lokasi anda tidak terdeteksi");
                  return;
                }
                setVisitLat(val.latitude);
                setVisitLng(val.longitude);
                setVisitPicture(val.path);
                setModalVisitOpen(true);
                setShowModal(false);
              }}
              onClose={() => {
                setShowModal(false);
                setTimeout(() => {
                  window.location.reload();
                }, 300);
              }}
            />
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size={"xs"}
        open={modalDailyWork}
        onClose={() => setModalDailyWork(false)}
      >
        <Modal.Header>
          <Modal.Title>Laporan Pekerjaan Harian</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="font-[700]">Jenis Pekerjaan</p>
            <SelectPicker
              block
              labelKey="name"
              valueKey="id"
              // searchable={false}
              data={workTypes}
              value={selectedWorkTypeID}
              onChange={(val) => {
                setSelectedWorkTypeID(val ?? "");
                setSelectedWorkType(workTypes.find((e) => e.id == val) ?? null);
              }}
            />
          </div>
          {selectedWorkType && (
            <div className="mb-4">
              <p className="text-sm font-[700]">
                Jumlah ({selectedWorkType?.unit_name})
              </p>
              {mountValue && (
                <CurrencyInput
                  className="form-control"
                  groupSeparator="."
                  decimalSeparator=","
                  value={workQty}
                  onValueChange={(value, _, values) => {
                    setWorkQty(values?.float ?? 0);
                  }}
                />
              )}
            </div>
          )}
          <div className="mb-4">
            <p className="font-[700]">Approver</p>
            <SelectPicker
              block
              value={employeeId}
              data={employees}
              labelKey="full_name"
              valueKey="id"
              onClean={() => {
                setEmployeeId(null);
              }}
              onSelect={(val) => {
                setEmployeeId(val);
                asyncLocalStorage.setItem(APPROVER_ID, val);
              }}
              onSearch={(val) => {
                if (val) {
                  getEmployees({ page: 1, limit: 5, search: val })
                    .then((v) => v.json())
                    .then((v) => setEmployees(v.data));
                }
              }}
            />
          </div>
          <div className="mb-4">
            <p className="text-sm font-[700]">File</p>
            <Uploader
              fileListVisible={false}
              draggable
              action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
              onUpload={(file) => {
                setIsLoading(true);
              }}
              headers={{
                authorization: `Bearer ${token}`,
              }}
              accept="image/*"
              onSuccess={(response, file) => {
                setIsLoading(false);
                // console.log(response.data.path);
                paths.push(response.data.path);
                urls.push(response.data.url);
                setPaths([...paths]);
                setUrls([...urls]);
                // setAttachment(response.data.path);

                // toaster.push(<Message type="success">Uploaded successfully</Message>);
              }}
              onError={() => {
                setIsLoading(false);
                errorToast("Upload Failed");
              }}
            >
              <div>
                <div
                  style={{
                    height: 200,
                    width: "100%",
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "center",

                    padding: 10,
                  }}
                >
                  <span>Click or Drag files to this area to upload</span>
                </div>
              </div>
            </Uploader>
            <div className="flex flex-wrap">
              {urls.map((e, i) => (
                <div className="relative" key={e}>
                  <TrashIcon
                    className="w-4 h-4 text-red-400 cursor-pointer right-1 top-1 absolute"
                    onClick={() => {
                      paths.splice(i, 1);
                      urls.splice(i, 1);
                      setPaths([...paths]);
                      setUrls([...urls]);
                    }}
                  />
                  <img
                    src={e}
                    className="w-16 h-16 object-cover rounded-md border m-2"
                  />
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalDailyWork(false);
            }}
          >
            Batal
          </Button>
          <Button
            appearance="primary"
            onClick={async () => {
              try {
                if (!selectedWorkTypeID) {
                  throw Error("Isi Jenis Pekerjaan");
                }

                setIsLoading(true);
                let req: WorkReportReq = {
                  attendance_id: attendance?.id,
                  work_date: moment().toISOString(),
                  work_type_id: selectedWorkTypeID,
                  units_completed: workQty,
                  submitted_at: moment().toISOString(),
                  approved_by_id: employeeId,
                  status: "SUBMITTED",

                  pictures: paths.map((e, i) => ({
                    picture: e,
                    picture_url: urls[i],
                  })),
                };

                await createWorkReport(req);
                successToast("Laporan Harian Telah Ditambahkan");
                setModalDailyWork(false);
                getWorkReports({ page: 1, limit: 0 }, attendance?.id)
                  .then((v) => v.json())
                  .then((v) => {
                    setWorkReports(v.data);
                  })
                  .catch((err) => {});
                setSelectedWorkTypeID("");
                setSelectedWorkType(null);
                setWorkQty(0);
                setMountValue(false);
                setTimeout(() => {
                  setMountValue(true);
                }, 50);
                setPaths([]);
                setUrls([]);
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalTask
        isOpen={modalTaskOpen}
        onClose={setModalTaskOpen}
        profile={profile}
        attendance={attendance}
        onSuccess={() => {
          todayAttendance()
            .then((v) => v.json())
            .then((v) => {
              if (v.data.id) {
                setAttendance(v.data);
              }
            });
        }}
      />
      <ModalVisit
        lat={visitLat}
        lng={visitLng}
        picturePath={visitPicture}
        mode={visitMode}
        isOpen={modalVisitOpen}
        activity={pendingVisitActivity}
        onClose={(val) => {
          setModalVisitOpen(val);
          setShowModal(false);
          setVisitLat(null);
          setVisitLng(null);
          setVisitPicture("");
          setVisitMode("VISIT_IN");
          window.location.reload();
        }}
        profile={profile}
        attendance={attendance}
        onSuccess={() => {
          todayAttendance()
            .then((v) => v.json())
            .then((v) => {
              if (v.data.id) {
                setAttendance(v.data);
              }
            });
        }}
      />
      <Modal size={"xs"} open={modalQR} onClose={() => setModalQR(false)}>
        <Modal.Header>
          <Modal.Title>Scan QR </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalQR && (
            <div className="scale-x-[-1]">
              <BarcodeScanner
                onCapture={(val) => {
                  if (val.format == "qr_code") {
                    setQrValue(val.rawValue);
                    setModalQR(false);
                  }
                }}
              />
              {/* <BarcodeScannerComponent
                width={500}
                height={500}
                stopStream={false}
                onUpdate={(err, result) => {}}
              
              >
                <p>Scan a barcode to continue</p>
                </BarcodeScannerComponent> */}
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size={"xs"}
        open={modalWorkReport}
        onClose={() => {
          setModalWorkReport(false);
          clearForm();
        }}
      >
        <Modal.Header>
          <Modal.Title>Pemeriksaan Laporan Perkerjaan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {workreportData.length > 0 && (
            <div className="flex flex-col gap-4">
              <div>
                <p className="font-bold">Tgl</p>
                <p>{moment(workreportData[1]).format("DD MMM YYYY")}</p>
              </div>
              <div>
                <p className="font-bold">Nama Karyawan</p>
                <p>{workreportData[5]}</p>
              </div>
              <div>
                <p className="font-bold">NIK</p>
                <p>{workreportData[6].replaceAll("NIK-", "")}</p>
              </div>
              <div>
                <p className="font-bold">Jenis Pekerjaan</p>
                <p>{workreportData[2]}</p>
              </div>
              <div>
                <p className="font-bold">Jumlah</p>
                <p>{`${workreportData[3]} ${workreportData[4]}`}</p>
              </div>

              <div>
                <p className="font-bold">Jumlah Yang Diterima</p>
                {mountValue && (
                  <CurrencyInput
                    className="form-control"
                    groupSeparator="."
                    decimalSeparator=","
                    max={parseFloat(workreportData[3])}
                    value={workQty}
                    onValueChange={(value, _, values) => {
                      if (
                        parseFloat(workreportData[3]) - (values?.float ?? 0) <
                        0
                      ) {
                        setWorkQty(parseFloat(workreportData[3]));
                      } else {
                        setWorkQty(values?.float ?? 0);
                      }
                    }}
                  />
                )}
              </div>
              <div>
                <p className="font-bold">Jumlah Yang Ditolak</p>
                <p>
                  {`${parseFloat(workreportData[3]) - workQty}`}{" "}
                  {workreportData[4]}
                </p>
              </div>
              <div>
                <p className="font-bold">Catatan</p>
                <textarea
                  placeholder="ex: Perdin ke bandung ...."
                  rows={5}
                  value={notes}
                  onChange={(el) => setNotes(el.target.value)}
                  className="form-control"
                />
              </div>
              {workreportData[7].split(",").length > 0 && (
                <div>
                  <p className="font-bold">Foto</p>
                  {workreportData[7].split(",").map((e) => (
                    <img
                      src={e}
                      key={randomStr(7)}
                      className="w-16 h-16 object-cover rounded-md border m-2"
                    />
                  ))}
                </div>
              )}
              <div className="mb-4">
                <p className="text-sm font-[700]">File</p>
                <Uploader
                  fileListVisible={false}
                  draggable
                  action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
                  onUpload={(file) => {
                    setIsLoading(true);
                  }}
                  headers={{
                    authorization: `Bearer ${token}`,
                  }}
                  accept="image/*"
                  onSuccess={(response, file) => {
                    setIsLoading(false);
                    // console.log(response.data.path);
                    paths.push(response.data.path);
                    urls.push(response.data.url);
                    setPaths([...paths]);
                    setUrls([...urls]);
                    // setAttachment(response.data.path);

                    // toaster.push(<Message type="success">Uploaded successfully</Message>);
                  }}
                  onError={() => {
                    setIsLoading(false);
                    errorToast("Upload Failed");
                  }}
                >
                  <div>
                    <div
                      style={{
                        height: 200,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 10,
                      }}
                    >
                      <span>Click or Drag files to this area to upload</span>
                    </div>
                  </div>
                </Uploader>
                <div className="flex flex-wrap">
                  {urls.map((e, i) => (
                    <div className="relative" key={e}>
                      <TrashIcon
                        className="w-4 h-4 text-red-400 cursor-pointer right-1 top-1 absolute"
                        onClick={() => {
                          paths.splice(i, 1);
                          urls.splice(i, 1);
                          setPaths([...paths]);
                          setUrls([...urls]);
                        }}
                      />
                      <img
                        src={e}
                        className="w-16 h-16 object-cover rounded-md border m-2"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalDailyWork(false);
            }}
          >
            Batal
          </Button>
          <Button
            appearance="primary"
            onClick={async () => {
              try {
                clearForm();
                await checkWorkReport(workreportData[0], {
                  amount_reported: parseFloat(workreportData[3]),
                  amount_approved: workQty,
                  amount_rejected: parseFloat(workreportData[3]) - workQty,
                  remarks: notes,
                  pictures: paths.map((e, i) => ({
                    picture: e,
                    picture_url: urls[i],
                  })),
                });
                setModalWorkReport(false);
                clearForm();
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={modalCamera} onClose={() => setModalCamera(false)}>
        <Modal.Header>{attendanceSiteData[2]}</Modal.Header>
        <Modal.Body>
          {modalCamera && (
            <div className="">
              <Webcam
                audio={false}
                ref={webcamRef}
                forceScreenshotSourceSize
                mirrored={true}
                videoConstraints={videoConstraints}
                screenshotFormat="image/jpeg"
                style={{}}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalCamera(false)}>Batal</Button>
          <Button appearance="primary" onClick={capture}>
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default Home;
