import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState, type FC } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi2";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { Pagination as RSPagination } from "rsuite";
import MainLayout from "../components/main_layout";
import { Notif } from "../model/notif";
import { LoadingContext } from "../objects/loading_context";
import { Pagination } from "../objects/pagination";
import { clearNotif, getNotifications, readNotif } from "../repositories/auth";
import { errorToast, toolTip } from "../utils/helper_ui";
import { VscClearAll } from "react-icons/vsc";
import { confirmDelete } from "../utils/helper";
import { FaDropbox } from "react-icons/fa6";

interface NotificationPageProps {}

const NotificationPage: FC<NotificationPageProps> = ({}) => {
  const nav = useNavigate();
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [notifications, setNotifications] = useState<Notif[]>([]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const notifClick = (e: Notif) => {
    switch (e.type) {
      case "NOTIFICATION_OVERTIME_FRONTEND":
        nav(`/approval/overtime/${e.ref_id}`);
        break
      case "NOTIF_SEND_EMPLOYEE_PAYSLIP":
        nav(`/payslip/${e.ref_id}`);
        break
      case "NOTIFICATION_ANNOUNCEMENT":
        nav(`/announcement/${e.ref_id}`);
        break
      case "NOTIFICATION_WORK_REPORT":
        nav(`/approval/work_report/${e.ref_id}`);
        break
      case "NOTIFICATION_WORK_REPORT_APPROVAL":
        nav(`/work_report/${e.ref_id}`);
        break
      default:
        break;
    }
  };

  useEffect(() => {
    if (!mounted) return;
    getAllNotifications();
  }, [mounted, page, limit]);

  const getAllNotifications = () => {
    setIsLoading(true);
    getNotifications({ page, limit })
      .then((v) => v.json())
      .then((v) => {
        setPagination(v.pagination);
        setNotifications(v.data);
      })
      .finally(() => setIsLoading(false))
      .catch((err) => errorToast(err));
  };
  return (
    <MainLayout >
      <div className="flex  flex-col  w-full h-full">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Notifikasi</h3>
          <div className="w-8">
            {toolTip(
              "Bersihkan Notifikasi",
              notifications.length > 0 && (
                <VscClearAll
                  className="text-red-400 cursor-pointer"
                  onClick={() => {
                    confirmDelete(() => {
                      clearNotif().then(() => getAllNotifications());
                    });
                  }}
                />
              )
            )}
          </div>
        </div>
        <div className="flex-1 p-4 bg-gray-50 overflow-y-auto ">
          {notifications.length == 0 && (
            <div className="flex h-full justify-center items-center gap-2 flex-col  p-4 mb-4">
              <FaDropbox size={64} className="text-sky-300" />
              <p className="text-sm font-bold">Tidak ada notifikasi</p>
            </div>
          )}
          {notifications.map((e) => (
            <div key={e.id} className="bg-white p-4 mb-4 cursor-pointer">
              <div className="flex flex-row justify-between items-center">
                <div
                  className="flex items-center gap-2"
                  onClick={() => notifClick(e)}
                >
                  <div>
                    <p className="text-sm font-bold">{e.title}</p>
                    <p className="text-sm">
                      <Moment fromNow>{e.created_at}</Moment>
                    </p>
                  </div>
                </div>
                <HiOutlineCheckCircle
                  size={24}
                  className="cursor-pointer text-gray-300 hover:text-green-400"
                  onClick={() => {
                    readNotif(e.id).then(() => getAllNotifications());
                  }}
                />
              </div>
              <div className="mt-4" onClick={() => notifClick(e)}>
                <p>{e.message}</p>
              </div>
            </div>
          ))}
          {(pagination?.total_pages ?? 0) > 1 && (
            <div className="bg-white flex justify-center p-4">
              <RSPagination
                total={pagination?.total_records ?? 0}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
              />
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
export default NotificationPage;
