import { HomeIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState, type FC } from "react";
import { LuCalendarDays } from "react-icons/lu";
import { PiBell, PiBellRinging } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";
import { Avatar } from "rsuite";
import { Profile } from "../model/auth";
import { getNotificationCount } from "../repositories/auth";
import {
  getStorageCompanyID,
  getStorageProfile,
  initials,
} from "../utils/helper";

interface NavMenuProps {}

const NavMenu: FC<NavMenuProps> = ({}) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [mounted, setMounted] = useState(false);
  const [countNofif, setCountNofif] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!mounted) return;

    getStorageCompanyID().then((v) => {
      if (v) {
        getNotificationCount()
          .then((v) => v.json())
          .then((v) => setCountNofif(v.data.count))
          .catch((err) => {});
        getStorageProfile().then((v) => setProfile(v));
      }
    });
  }, [mounted]);

  return (
    <nav className="bg-white shadow fixed bottom-0 w-full max-w-md mx-auto nav">
      <div className="flex justify-around mb-3">
        <Link
          to="/"
          className={`flex flex-col items-center py-2 ${
            location.pathname == "/" ? " text-purple-700" : "text-gray-600"
          } hover:text-gray-900`}
        >
          <HomeIcon className="w-6" />
          <span className="text-xs"></span>
        </Link>

        <Link
          to="/calendar"
          className={`flex flex-col items-center py-2 ${
            location.pathname.includes("/activity")
              ? " text-purple-700"
              : "text-gray-600"
          } hover:text-gray-900`}
        >
          <LuCalendarDays size={24} className="w-6" />
          <span className="text-xs"></span>
        </Link>

        <Link
          to="/notification"
          className={`flex flex-col items-center py-2 ${
            location.pathname == "/reimbursement"
              ? " text-purple-700"
              : "text-gray-600"
          } hover:text-gray-900`}
        >
          {countNofif > 0 ? (
            <PiBellRinging size={24} className="cursor-pointer text-red-500" />
          ) : (
            <PiBell size={24} className="cursor-pointer " />
          )}
          <span className="text-xs"></span>
        </Link>

        <Link
          to="/profile"
          className={`flex flex-col items-center py-2 ${
            location.pathname == "/profile"
              ? " text-purple-700"
              : "text-gray-600"
          } hover:text-gray-900`}
        >
          {profile?.picture ? (
            <img
              src={profile?.picture}
              alt=""
              className=" object-cover rounded-full aspect-square w-6 border-2 border-violet-700"
            />
          ) : (
            <UserCircleIcon className="w-6" />
          )}

          <span className="text-xs"></span>
        </Link>
      </div>
    </nav>
  );
};
export default NavMenu;
