import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { BackwardIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../objects/loading_context";
import { errorToast } from "../utils/helper_ui";
import { getPayslips } from "../repositories/auth";
import { Pagination } from "../objects/pagination";
import { PayRoll } from "../model/payroll";
import Moment from "react-moment";
import { Badge, Pagination as RSPagination } from "rsuite";

interface PayslipProps {}

const Payslip: FC<PayslipProps> = ({}) => {
  const nav = useNavigate();
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [page, setPage] = useState(1);
  const [search, set] = useState("");
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [payslips, setPayslips] = useState<PayRoll[]>([]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAllPaySlips();
  }, [page, limit, search, mounted]);

  const getAllPaySlips = async () => {
    try {
      setIsLoading(true);
      const resp = await getPayslips({ page, limit, search });
      const respJson = await resp.json();
      setPayslips(respJson.data);
      setPagination(respJson.pagination);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <MainLayout hideMenu>
      <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center">
        <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
        <h3>Payslip</h3>
        <div className="w-6"></div>
      </div>
      <div className="flex-1 p-4 bg-gray-50 overflow-y-auto w-full">
        {payslips.map((e) => (
          <div
            key={e.id}
            className="flex flex-row  gap-4 bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0 "
            onClick={() => nav(`/payslip/${e.id}`)}
          >
            <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
              <Moment format="DD">{e.start_date}</Moment>
              <Moment
                format="MMM YYYY"
                className="text-[8pt] text-center text-red-400"
              >
                {e.start_date}
              </Moment>
            </div>
            <div className="flex  flex-1 flex-row justify-between">
              <div className="py-2">
                {/* <p
                    className={`${
                      e.absent ? "text-red-400" : "text-blue-400"
                    } text-sm`}
                  >
                  </p> */}

                <div className="flex flex-col">
                  <div>
                    {e.status == "DRAFT" && (
                      <Badge
                        color="cyan"
                        className="text-xs"
                        content={e.status}
                      />
                    )}
                    {e.status == "RUNNING" && (
                      <Badge
                        color="violet"
                        className="text-xs"
                        content={e.status}
                      />
                    )}
                    {e.status == "FINISHED" && (
                      <Badge
                        color="green"
                        className="text-xs"
                        content={e.status}
                      />
                    )}
                  </div>
                  <p className="text-sm">{e.title}</p>
                  {e.notes && <p className="text-xs">{e.notes}</p>}
                  {e.pay_roll_number && <p className="text-xs">{e.pay_roll_number}</p>}
                </div>
              </div>
            </div>
          </div>
        ))}

        {(pagination?.total_pages ?? 0) > 1 && (
          <div className="bg-white flex justify-center p-4">
            <RSPagination
              total={pagination?.total_records ?? 0}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};
export default Payslip;
