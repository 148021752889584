import { TOKEN, PERMISSIONS, PROFILE, COMPANIES, COMPANY_ID, COMPANY } from "./constant";
import * as CryptoJS from 'crypto-js';
import Swal from "sweetalert2";
import moment from 'moment';
import { errorToast, successToast } from "./helper_ui";
import { subscribeChannelIos } from "../repositories/auth";
export async function customFetch(...args) {
    let [resource, config, multipart, fullUrl] = args;
    const token = await asyncLocalStorage.getItem(TOKEN)
    const companyID = await asyncLocalStorage.getItem(COMPANY_ID)
    // const companyID = localStorage.getItem(SELECTED_COMPANY_ID)
    // const merchantID = localStorage.getItem(SELECTED_MERCHANT_ID)

    if (!config) {
        config = {
            headers: {
                authorization: `Bearer ${token ?? null}`
            }
        }
    } else {
        config["headers"] = {
            authorization: `Bearer ${token ?? null}`
        }
    }


    if (!multipart) {
        config["headers"]["Content-Type"] = "application/json"
    }
    if (companyID) {
        config["headers"]["ID-Company"] = companyID
    }
    config["headers"]["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone
    // if (merchantID) {
    //     config["headers"]["ID-Merchant"] = merchantID
    // }

    try {
        // request interceptor here
        const response = await fetch(fullUrl ? resource : `${process.env.REACT_APP_API_URL}/${resource}`, config);

        if (response.status !== 200) {
            var respJson = await response.json()
            throw (respJson.message)
        }

        // response interceptor here
        return response;
    } catch (error) {

        if (`${error}`.includes("token is expired")) {
            await clearStorage()
            window.location.href = "/login"
        }
        return Promise.reject(error)
    }


}



export async function setProfileStorage(profile) {
    await asyncLocalStorage.setItem(PROFILE, JSON.stringify(profile));
}
export async function asyncSetStorage({ token, permissions, profile }) {
    await asyncLocalStorage.setItem(TOKEN, token);
    await asyncLocalStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
    await asyncLocalStorage.setItem(PROFILE, JSON.stringify(profile));
}


export async function setStorageCompanies(companies) {
    await asyncLocalStorage.setItem(COMPANIES, JSON.stringify(companies));
}


export async function getStorageCompanies() {
    let companies = await asyncLocalStorage.getItem(COMPANIES)
    if (companies) return JSON.parse(companies)
    return []
}

export async function setStorageCompanyID(companyID) {
    await asyncLocalStorage.setItem(COMPANY_ID, companyID);
}

export async function setStorageCompany(company) {
    await asyncLocalStorage.setItem(COMPANY, JSON.stringify(company));
}

export async function getStorageCompanyID() {
    return await asyncLocalStorage.getItem(COMPANY_ID);
}
export async function getStorageCompany() {
    let company = await asyncLocalStorage.getItem(COMPANY)
    if (company) return JSON.parse(company)
    return null
}

export async function getStoragePermissions() {
    let permissions = await asyncLocalStorage.getItem(PERMISSIONS)
    if (permissions) return JSON.parse(permissions)
    return []
}
export async function getStorageProfile() {
    let profile = await asyncLocalStorage.getItem(PROFILE)
    if (profile) return JSON.parse(profile)
    return null
}


export async function clearStorage() {
    await asyncLocalStorage.removeItem(TOKEN);
    await asyncLocalStorage.removeItem(PERMISSIONS);
    await asyncLocalStorage.removeItem(COMPANIES);
    await asyncLocalStorage.removeItem(COMPANY_ID);
    await asyncLocalStorage.removeItem(COMPANY);
}

export const asyncLocalStorage = {
    async setItem(key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, encrypt(value));
        });
    },
    async getItem(key) {
        return Promise.resolve().then(function () {
            let data = localStorage.getItem(key) || "";
            return decrypt(data) || null;
        });
    },
    async removeItem(key) {
        return Promise.resolve().then(function () {
            return localStorage.removeItem(key);
        });
    }
};


function encrypt(txt) {
    return CryptoJS.AES.encrypt(txt, process.env.REACT_APP_SECRET_KEY).toString();
}

function decrypt(txtToDecrypt) {
    return CryptoJS.AES.decrypt(txtToDecrypt, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
}

export function randomStr(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}



export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}




export function nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export function numberToDuration(num) {
    let hours = Math.floor(num / 60)
    let minutes = num % 60
    return (hours ? `${hours} Jam` : '') + ' ' + (minutes ? `${minutes} Menit` : '');
}
export function stringHourToNumber(hour) {
    try {
        let split = hour.split(":")
        return (parseInt(split[0]) * 60) + parseInt(split[1])
    } catch (error) {
        return 0
    }


}


export function initials(name) {
    if (!name) return ""
    return name.split(" ").map((n) => `${n[0]}`.toUpperCase()).join("");
}



export function confirmDelete(confirmed, title, desc, confirmText) {
    Swal.fire({
        title: title ?? "Anda Yakin",
        text: desc ?? "Anda tidak akan dapat mengembalikan proses ini!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmText ?? "Ya, Hapus!",
        cancelButtonText: "Batal"
    }).then((result) => {
        if (result.isConfirmed) {
            confirmed()
        }
    });
}



export const money = (val, friction = 2) => {
    if (!val) return 0
    return val.toLocaleString('id-ID', { maximumFractionDigits: friction });
}



export const urlBase64ToUint8Array = () => {
    let base64String = process.env.REACT_APP_VAPID_PUBLIC_KEY
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};


export const checkAuth = async () => {
    const token = localStorage.getItem('google_token');
    console.log(token)
    if (!token) return null
    const response = await fetch(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${token}`);
    const data = await response.json();
    if (data.error) {
        console.log('Token is invalid:', data.error);
        return null;
    }
    return token;
};

export const IsIOS = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
    ].includes(navigator?.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document) || (navigator.userAgent.includes("MacIntel") && "ontouchend" in document)
}

export const initServiceWorker = async () => {
    let swRegistration = await navigator.serviceWorker.register('/sw.js')
    let pushManager = swRegistration.pushManager;

    if (!isPushManagerActive(pushManager)) {
        return;
    }

    return await pushManager.permissionState({ userVisibleOnly: true });
}

export const subscribeToPush = async () => {
    // Public part of VAPID key, generation of that covered in README
    // All subscription tokens associated with that key, so if you change it - you may lose old subscribers
    const VAPID_PUBLIC_KEY = process.env.REACT_APP_VAPID_PUBLIC_KEY;

    let swRegistration = await navigator.serviceWorker.getRegistration();
    let pushManager = swRegistration.pushManager;
    if (!isPushManagerActive(pushManager)) {
        return;
    }
    let subscriptionOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array()
    };
    try {
        let subscription = await pushManager.subscribe(subscriptionOptions);
        // console.log(subscription);
        await subscribeChannelIos(JSON.stringify(subscription))
        successToast("Success Subscribe Notification Channel")
        return Promise.resolve("Success Subscribe Notification Channel")

        // Here you can send fetch request with subscription data to your backend API for next push sends from there
    } catch (error) {
        // document.getElementById('active_sub').style.display = 'block';
        errorToast('User denied push permission')
        return Promise.error("User denied push permission")
    }
}

export const isStandAlone = () => {
    return window.navigator.standalone
}

function isPushManagerActive(pushManager) {
    if (!pushManager) {
        if (!window.navigator.standalone) {
            document.getElementById('add-to-home-screen').style.display = 'block';
        } else {
            throw new Error('PushManager is not active');
        }
        document.getElementById('subscribe_btn').style.display = 'none';
        return false;
    } else {
        return true;
    }
}




export function distance(lat1, lon1, lat2, lon2) {
    const R = 6371e3;
    const p1 = lat1 * Math.PI / 180;
    const p2 = lat2 * Math.PI / 180;
    const deltaP = p2 - p1;
    const deltaLon = lon2 - lon1;
    const deltaLambda = (deltaLon * Math.PI) / 180;
    const a = Math.sin(deltaP / 2) * Math.sin(deltaP / 2) +
        Math.cos(p1) * Math.cos(p2) *
        Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R;
    return d;
}